body {
  margin: 0;
  padding: 0;
  background: #161e27;
  color: #415974;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
